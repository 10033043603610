<template>
  <div class="wrapper">
    <el-carousel class="slideBox" height="100%" :interval="5000" arrow="never">
      <!-- <el-carousel-item> -->
      <div class="slideBox1"></div>
      <!-- </el-carousel-item> -->
    </el-carousel>
    <div class="wrapper-content">
      <div class="row_content">
        <div class="content">
          <div style="margin: 30px 0">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: '/order' }">在线订购</el-breadcrumb-item>
              <el-breadcrumb-item>{{ proInfo.name }}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <!-- <div class="newsInfo_title">{{ proInfo.name }}</div> -->
          <div class="newsInfo_content" v-html="proInfo.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script scoped>
import http from "@/api/front";
export default {
  data() {
    return {
      query: { id: null },
      proInfo: {},
    };
  },
  mounted() {
    this.query.id = this.$route.query.id;
    this.getProInfo();
  },
  methods: {
    getProInfo() {
      http.getProInfo(this.query, (res) => {
        if (res.success) {
          this.proInfo = res.data;
          document.title = document.title.replace("产品介绍", this.proInfo.name);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/*slideBox 开始*/
.slideBox {
  width: 100%;
  height: 80px;
  overflow: hidden;
  z-index: 1;
}
.slideBox1 {
  width: 100%;
  height: 100%;
  background-color: rgb(7, 55, 99);
  // background-image: url("/images/products/slide_1.jpg");
  background-position: 0px 0px;
  background-size: cover;
  z-index: 1;
}
.row_content {
  .content {
    .newsInfo_title {
      font-size: 45px;
      color: #ddd;
      text-align: center;
      letter-spacing: 2px;
      font-family: "黑体";
      margin: 10px auto;
      line-height: 130px;
      width: 80%;
      background-color: #13234f;
    }
    .newsInfo_content {
      text-align: center;
      width: 90%;
      margin-bottom: 140px;
    }
  }
}
</style>
